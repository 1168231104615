import { Box, HStack, StackProps, VStack } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Heading, Subheading, Text, Title } from 'ui';
import Layout from '../components/layout';
import Registration from '../components/registration';
import SEO from '../components/seo';

const bankStepsImg = `../assets/images/game/bank-steps.png`;
const evolutionImg = `../assets/images/game/genopet-evolutions.png`;
const leaderboardImg = `../assets/images/game/leaderboard.png`;
const nftImg = `../assets/images/game/nfts.png`;
const summonImg = `../assets/images/game/summon.png`;
const worldImg = `../assets/images/game/world.png`;
const heroImg = `../../static/imgs/hero-bg.png`;
const battleImg = `../assets/images/game/battle.png`;

const Slide = ({
  description,
  heading,
  image,
  name,
  subHeading,
  subTitle,
  title,
  imageWrapperProps,
  wrapperProps,
}: {
  description: string;
  heading: string;
  image: string;
  name: string;
  subHeading: string;
  subTitle?: string;
  title?: string;
  imageWrapperProps?: StackProps;
  wrapperProps?: StackProps;
}) => {
  // static image does not take dynamic src props.
  // https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/#restrictions-on-using-staticimage
  const generateImage = () => {
    switch (image) {
      case `summon`:
        return (
          <StaticImage
            placeholder="blurred"
            src={summonImg}
            quality={100}
            alt={`${name}-img`}
          />
        );
      case `bankSteps`:
        return (
          <StaticImage
            placeholder="blurred"
            src={bankStepsImg}
            quality={100}
            alt={`${name}-img`}
          />
        );
      case `evolution`:
        return (
          <StaticImage
            placeholder="blurred"
            src={evolutionImg}
            quality={100}
            alt={`${name}-img`}
          />
        );
      case `nft`:
        return (
          <StaticImage
            placeholder="blurred"
            src={nftImg}
            quality={100}
            alt={`${name}-img`}
          />
        );
      case `leaderboard`:
        return (
          <StaticImage
            placeholder="blurred"
            src={leaderboardImg}
            quality={100}
            alt={`${name}-img`}
          />
        );
      case `openWorld`:
        return (
          <StaticImage
            placeholder="blurred"
            src={worldImg}
            quality={100}
            alt={`${name}-img`}
          />
        );
      case `battle`:
        return (
          <StaticImage
            placeholder="blurred"
            src={battleImg}
            quality={100}
            alt={`${name}-img`}
          />
        );
      default:
        return (
          <StaticImage
            placeholder="blurred"
            src={heroImg}
            quality={100}
            alt={`${name}-img`}
          />
        );
    }
  };

  return (
    <HStack
      h={{ base: `full`, lg: `auto` }}
      align="center"
      justify="center"
      ml="auto"
      mr="auto"
      flexDir={{ base: `column`, lg: `row` }}
      gap={{ base: 5, lg: 0 }}
      id={name}
      {...wrapperProps}
    >
      <VStack
        w="full"
        h="auto"
        maxW="420px"
        align="flex-start"
        justify="center"
      >
        {(title || subTitle) && (
          <Box mb={8}>
            <Subheading as="div" size="sm" color="teal.text">
              {subTitle}
            </Subheading>
            <Title as="h1" color="#00FFC8" w="full">
              {title}
            </Title>
          </Box>
        )}
        <Box>
          <Heading as="h3" size="xl" color="#00FFC8">
            <Subheading as="div" color="#BDFFF1" size="sm">
              {subHeading}
            </Subheading>
            {heading}
          </Heading>
        </Box>
        <Text color="#BDFFF1">{description}</Text>
      </VStack>
      <VStack
        w="auto"
        h="auto"
        justify="center"
        align="flex-start"
        {...imageWrapperProps}
      >
        {generateImage()}
      </VStack>
    </HStack>
  );
};

const Product = () => (
  <Layout>
    <SEO
      title="Game"
      description="Your Genopet is unique to you made in your likeness, fueled by your daily activity. Explore a world powered by the steps you take every day."
    />
    <VStack
      w="full"
      pos="relative"
      px="16px"
      h="full"
      maxH="full"
      pb={{ base: `200px` }}
    >
      <VStack w="full" pos="absolute" top="10%" opacity={0.4} align="center">
        <StaticImage
          placeholder="blurred"
          src={heroImg}
          quality={100}
          alt="hero-img"
        />
      </VStack>
      <VStack w="full" h="auto" align="center" justify="center">
        <Slide
          name="summon"
          subTitle="Genopets &"
          title="the genoverse"
          subHeading="Summon"
          heading="your genopet"
          description="Your journey begins with meeting your digital familiar in the Genoverse
          who will travel by your side. Made in your likeness from the start, a
          Genopet is infused with your personality; a digital representation of
          you. Genopets is free-to-play, everyone can move-to-earn. Stay active to
          level up and customize your Genopet to increase its rarity and value
          over time."
          image="summon"
          wrapperProps={{ mt: `90px` }}
        />
        <Slide
          name="bank-steps"
          subHeading="Start"
          heading="moving to play"
          description="Treadmill at the gym? Walking around the mall? Your Genopet is
          always by your side making it fun and rewarding to live an active
          lifestyle. Set a daily step goal and challenge yourself to grow.
          Just don’t forget to bank your steps each day to reap the rewards
          of your hard work and convert steps to Energy you’ll use to
          upgrade, customize, and evolve your pet."
          image="bankSteps"
          wrapperProps={{ flexDir: { base: `column`, lg: `row-reverse` } }}
        />
        <Slide
          name="evolution"
          subHeading="Level Up"
          heading="to evolve"
          description="Take care of your Genopet by taking care of yourself. Bank your
          steps everyday to claim the rewards of your day’s activity. Over
          time, your Genopet will level up and evolve. The higher the level
          of your Genopet, the more XP, the greater its in-game abilities,
          and the more rare it can become. Nurture your Genopet by feeding,
          playing with, and caring for it to elevate its mood each day."
          image="evolution"
          imageWrapperProps={{ w: { base: `auto`, sm: `50%` } }}
        />
        <Slide
          name="nft"
          subHeading="Earn KI"
          heading="craft nfts"
          description="Boost your in-game rewards by acquiring a Habitat, a home for your Genopet, and start crafting power-ups, converting your Energy to KI token, and open up a whole new world of strategic gameplay. Refine Crystals and practice Alchemy to obtain rare NFTs you can use to upgrade your Genopet or sell in the marketplace fueling the economy in-game."
          image="nft"
          imageWrapperProps={{
            w: { base: `full`, sm: `50%` },
            maxW: { base: `unset`, lg: `45%` },
            align: { base: `center`, sm: `flex-start` },
            pr: { base: `0px`, lg: `24px` },
          }}
          wrapperProps={{
            flexDir: { base: `column`, lg: `row-reverse` },
            pt: { base: `3%`, lg: `unset` },
          }}
        />
        <Slide
          name="leaderboard"
          subHeading="Battle"
          heading="for glory"
          description="Bank your steps and watch your Genopet climb the leaderboard as you prepare for battle. Challenge other players as you sharpen your reaction time, memory, and overall cognitive skills in fast-paced turn based minigames. Genopets is designed to augment your life and encourage you to build healthy habits. Will you be the next Arena Champion?"
          image="battle"
          wrapperProps={{ pt: { base: `3%`, lg: `unset` } }}
        />
        <Slide
          name="open-world"
          subHeading="Explore An"
          heading="open world"
          description="Once you’ve had your fill of glory, prepare yourself to travel Esoterra and immerse yourself in the storyline fighting off nanobots invading their world. Embark on quests with friends or travel solo and discover hidden realms to unlock secret Recipes for crafting rare items that are sure to aid you on your journey to the center of the Genoverse."
          image="openWorld"
          wrapperProps={{ flexDir: { base: `column`, lg: `row-reverse` } }}
        />
        <Registration headingColor="#FFC000" textColor="#FFECB2" showPets />
      </VStack>
    </VStack>
  </Layout>
);

export default Product;
